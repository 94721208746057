import React, { useContext } from 'react';
import constants from '../context/constants';
import { PageContext } from './provider';
import logo from '../assets/logo2.png';

const Header = () => {
    const { state } = useContext(PageContext);

    const statusText = value => {
        switch (value) {
            case constants.PAGE_STATUS_LOADING:
                return 'loading';
            case constants.PAGE_STATUS_LOADED:
                return 'live';
            case constants.PAGE_STATUS_ERROR:
                return 'offline';
            default:
                return 'loading';
        }
    };

    var test = logo;

    return (
        <div className='header'>
            <a href='https://www.maropost.com/'>
                <img
                    alt="Maropost logo"
                    width="400"
                    src={logo}
                />
            </a>
            <span class='status'>{ statusText(state.pageStatus) }</span>
        </div>
    );
};

export default Header;
