import React from 'react';
import { PageProvider } from './provider';
import Header from './header';
import Globe from './globe';
import Footer from './footer';

const App = () => {
  return (
      <PageProvider>
        <Header/>
        <Globe/>
        <Footer/>
      </PageProvider>
  );
};

export default App;
