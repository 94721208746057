import constants from './constants';

export const pageReducer = (state, action) =>{
    switch (action.type) {
        case constants.CHANGE_DAY_TIME:
            return {
                ...state,
                isDayTime: action.data
            };
        case constants.UPDATE_ORDERS:
            return {
                ...state,
                ordersData: action.data
            };
        case constants.UPDATE_PAGE_STATUS:
            return {
                ...state,
                pageStatus: action.data
            };
        default:
            return state;
    }
};
