import React, { useContext, useEffect, useState } from 'react';
import { PageContext } from './provider';

const Footer = () => {
    const { state } = useContext(PageContext);

    const [ totalOrders, setTotalOrders ] = useState('-');

    const [ totalSales, setTotalSales ] = useState('-');

    useEffect(() => {
        // Get total orders
        state.ordersData.length > 0 ? setTotalOrders(state.ordersData.length) : setTotalOrders('-');

        // Gen total sales
        const grandTotal = state.ordersData.reduce((accumulator, item) => {
            return accumulator + parseFloat(item.GrandTotal);
        }, 0);

        grandTotal > 0 ? setTotalSales(grandTotal.toFixed(2)) : setTotalSales('-');
    }, [ state.ordersData ]);

    return (
        <div className='footer'>
            <p>Orders per minute</p>
            <h1>{totalOrders}</h1>
            <p>Sales per minute (AUD)</p>
            <h1>{totalSales}</h1>
        </div>
    );
};

export default Footer;
