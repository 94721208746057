const environment = process.env.REACT_APP_ENV || 'uat';

const urls = {
    uat: 'https://localhost:44313/api/order',
    staging: 'https://uatappasvsdb001.azurewebsites.net/api/order',
    //production: 'https://uatappasvsdb001.azurewebsites.net/api/order',
    production: 'https://retailworldorders.maropost.com/api/order'
};

const urlEndpoint = urls[environment];

export default {
    CHANGE_DAY_TIME: 'CHANGE_DAY_TIME',
    UPDATE_ORDERS: 'UPDATE_ORDERS',
    UPDATE_PAGE_STATUS: 'UPDATE_PAGE_STATUS',
    URL_ENDPOINT: urlEndpoint,
    PAGE_STATUS_LOADING: 'loading',
    PAGE_STATUS_LOADED: 'loaded',
    PAGE_STATUS_ERROR: 'error'
};

const OPACITY = 0.7;

export const salesChannelColourMap = {
    Amazon: `rgba(255, 153, 0, ${OPACITY}))`,
    Catch: `rgba(122, 205, 43, ${OPACITY}))`,
    eBay: `rgba(243, 175, 2, ${OPACITY}))`,
    Kogan: `rgba(231, 21, 69, ${OPACITY}))`,
    'Control Panel': `rgba(8, 62, 82, ${OPACITY}))`,
    'Neto POS': `rgba(71, 191, 238, ${OPACITY}))`,
    Website: `rgba(86, 193, 168, ${OPACITY}))`,
    Other: `rgba(255, 255, 255, ${OPACITY}))`
};
