import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactGlobe from 'react-globe.gl';
import { PageContext } from './provider';
import { useWindowSize } from '../hooks/useWindowSize';
import earthDay from '../assets/earth-day.jpg';
import earthNight from '../assets/earth-night.jpg';
import { salesChannelColourMap } from '../context/constants';

const Globe = () => {
    const { state } = useContext(PageContext);

    const size = useWindowSize();

    const globeEl = useRef();

    const [ locations, setLocations ] = useState([]);

    const [ globeImageUrl, setGlobeImageUrl ] = useState(earthNight);

    const [ backgroundColor, setBackgroundColor ] = useState('#000011');

    const orderPointColour = value => {
        switch (value.toLowerCase()) {
            case ('amazon au'):
            case ('amazon us'):
                return [ salesChannelColourMap.Amazon, salesChannelColourMap.Amazon ];
            case ('catch'):
                return [ salesChannelColourMap.Catch, salesChannelColourMap.Catch ];
            case ('ebay'):
                return [ salesChannelColourMap.eBay, salesChannelColourMap.eBay ];
            case ('kogan'):
                return [ salesChannelColourMap.Kogan, salesChannelColourMap.Kogan ];
            case ('control panel'):
                return [ salesChannelColourMap['Control Panel'], salesChannelColourMap['Control Panel'] ];
            case ('neto pos'):
                return [ salesChannelColourMap['Neto POS'], salesChannelColourMap['Neto POS'] ];
            case ('website'):
                return [ salesChannelColourMap.Website, salesChannelColourMap.Website ];
            default:
                return [ salesChannelColourMap.Other, salesChannelColourMap.Other ];
        }
    };

    useEffect(() => {
        // Gen order data
        const arcsData = state.ordersData.map(order => ({
            startLat: order.SourceLatLong[0],
            startLng: order.SourceLatLong[1],
            endLat: order.DestLatLong[0],
            endLng: order.DestLatLong[1],
            label: order.SalesChannel || 'Other',
            color: orderPointColour(order.SalesChannel || 'Other')
        }));

        setLocations(arcsData);
    }, [ state.ordersData ]);

    useEffect(() => {
        // Default Australia pointOfView and auto-rotate
        globeEl.current.pointOfView({ lat: -15, lng: 150, altitude: 1.75 });
        globeEl.current.controls().autoRotate = true;
        globeEl.current.controls().autoRotateSpeed = 0.05;
    }, []);

    useEffect(() => {
        // day or night globe
        if (state.isDayTime) {
            setGlobeImageUrl(earthDay);
            setBackgroundColor('#006baf');
        }
    }, [ state.isDayTime ]);

    return <ReactGlobe
        ref={globeEl}
        width={size.width}
        height={size.height}
        globeImageUrl={globeImageUrl}
        backgroundColor={backgroundColor}

        arcsData={locations}
        arcStartLat={'startLat'}
        arcStartLng={'startLng'}
        arcEndLat={'endLat'}
        arcEndLng={'endLng'}
        arcLabel={'label'}
        arcColor={'color'}
        arcAltitudeAutoScale={0.7}
        arcStroke={0.1}

        arcDashLength={0.5}
        arcDashGap={1}
        arcDashInitialGap={() => Math.random()}
        arcDashAnimateTime={5000}
        arcsTransitionDuration={0}
    />;
};

export default Globe;
